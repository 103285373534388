<template>
  <div class="login">
    <div class="loginLeft">
      <div class="loginL1">Paas</div>
      <div class="loginL2">Paas system</div>
      <div class="loginL3">
        <div>高效</div>
        <div>专业</div>
        <div>协同</div>
      </div>
    </div>
    <div class="loginRight">
      <div class="fontBox">
        <div>Welcome To Login</div>
        <div>欢迎登录后台系统</div>
        <div @click="saoma=!saoma">
          <img src="../assets/login/tel.png" alt="" v-if="saoma">
          <img src="../assets/login/erwei.png" alt="" v-else/>
        </div>
      </div>
      <div class="logo" v-if="!saoma">
        <div>
<!--          <img src="../assets/login/logo.png" alt="" />-->
        </div>
        <el-form  :model="user" :rules="userRule" ref="ruleForm">
          <el-form-item label="" prop="username"  :error="resError">
            <div style="margin-top: 78px" class="inputBox" @keydown.enter="loginBtn">
              <el-input type="text" placeholder="请输入账号" autofocus v-model="user.username" maxlength="18"/>
              <div class="titleBox">
                账&emsp;号
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" prop="password" :error="resError">
            <div style="margin-top: 45px" class="inputBox" @keydown.enter="loginBtn">
              <el-input type="password" placeholder="请输入密码"  v-model="user.password" maxlength="18" show-password />
              <div class="titleBox">
                密&emsp;码
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div style="text-align: right;margin-top: 15px">
          <el-checkbox v-model="savePass"><span style="color: black">记住密码</span></el-checkbox>
        </div>
        <div style="margin-top: 20px" @click="loginBtn">
          <button v-if="!login" style="cursor: pointer">登录</button>
          <el-button type="primary" :loading="true" v-else>登录中</el-button>
        </div>
      </div>
      <div class="QR" v-else>
<!--        <div style="color: #a7a5a5;font-size: 15px">二维码</div>-->
        <div class="QRCode">

        </div>
        <div style="color:rgba(0, 0, 0, 1);font-size: 14px">扫描二维码登录</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {login,afterGetData} from "../api/public";
  import md5 from  '../util/js/md5'
  import HF from "../util/myApi";
  export default {
  name: "login",
  data(){
    return{
      saoma:false,
      login:false,
      user:{
        username:'',
        password:'',
      },
      userRule:{
        username:[
          {required:true,message:'账号不能为空',trigger:'change'},
          {pattern:/^[A-Za-z0-9]+$/,message: '请输入有效数据',trigger: 'change'}
        ],
        password:[
          {required:true,message:'密码不能为空',trigger:'change'},
        ]
      },
      resError:'',
      webIM:{
        userName:'',
        password:'',
      },
      getCompanyReq:{
        pageNo:1,
        pageSize:20
      },
      savePass:false,//是否记住密码 ture是 false否
    }
  },
  created(){
    if(HF.getCookie('PAAS_ACTSAVE')){
      this.user.username=''
      this.user.password=''
      const {username,password}  = JSON.parse(HF.getCookie('PAAS_ACTSAVE'))
      this.user.username=username
      this.user.password=password
      this.savePass=true
    }
  },
  methods:{
    loginBtn(){
      if(this.login){
        return
      }
      this.login=true
      this.resError = ''//错误信息提示清空
      this.$refs['ruleForm'].validate((valid)=>{
        if (valid) {
          this.gotoSystem()
        } else {
          this.login=false
          return false;
        }
      })
    },
    gotoSystem(){
        const {username,password} = this.user
        const user = {username,password:md5.hexMD5(password)}
        return new Promise(resolve => {
          this.$store.commit('tokenTimeOut')
          if(this.savePass){
            this.$iApi.setCookie('PAAS_ACTSAVE',JSON.stringify(this.user),7)
          }else{
            this.$iApi.setCookie('PAAS_ACTSAVE','',0)
          }
          login(user).then(res=>{
            if(res.code==1000){
              if(!res.data) return
              this.$store.commit('loginInfoSave',res.data)//保存登录用户信息
              this.$store.commit('justLoginState')//刚刚登录的标记
              localStorage.setItem('paas_loginInfo',JSON.stringify(res.data))
              sessionStorage.setItem('PAAS_TOKEN',JSON.stringify(res.data.token))
              this.afterGetData().then(()=>{
                  if(this.$route.meta.from&&this.$route.meta.from!='/'){
                      this.login=false;
                      //检测路由权限是否有该路由
                      let flag = false
                      this.$router.getRoutes().forEach(item=>{
                          if(item.path==this.$route.meta.from){
                              flag=true
                          }
                      })
                      if(flag){
                          if(this.$route.meta.from!='/login'){
                              this.$router.push(this.$route.meta.from)
                          }else{
                              this.$router.push('/')
                          }
                      }else{
                          this.$router.push('/')
                      }
                      let timeout = setTimeout(()=>{
                          this.login=false;
                          clearTimeout(timeout)
                      },1000)
                      //检测路由权限是否有该路由
                  }else {
                      this.$router.push('/')
                      let timeout = setTimeout(()=>{
                          this.login=false;
                          clearTimeout(timeout)
                      },1000)
                  }
              }).catch(()=>{
                this.login=false;
              })
            }else{
              if(res.code==8006){
                this.resError='该用户已被禁用'
              }
              if(res.code==9999){
                this.resError='账号或密码错误'
              }
              this.login=false;
            }
          }).catch(()=>{
            resolve()
            this.login=false;
          })
        })

     },
    afterGetData(){
     return new Promise(resolve => {
         afterGetData().then(res=>{
         resolve()
         if(res.code==1000){
            localStorage.setItem('paas_dict',res.data.BaseDictionary)
            localStorage.setItem('paas_routerMenu',res.data.SystemMenu)
         }
       })
     })
    },
  }
};
</script>

<style scoped>
  @media screen and (max-width: 1720px){
    .login {
      width: 100%;
      height: 100vh;
      background: url("../assets/login/newbg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  @media screen and (min-width: 1720px){
    .login {
      width: 100%;
      height: 100vh;
      background: url("../assets/login/newbg.jpg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

.loginLeft {
  width: 178px;
  height: 101px;
  position: absolute;
  left: 84px;
  top: 55px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.loginRight {
  width: 701px;
  height: 100%;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  box-shadow: -6px 0px 12px 0px rgba(56, 103, 255, 0.58);
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.loginL1 {
  opacity: 1;
  font-size: 36px;
  font-weight: 900;
  color: rgba(148, 159, 205, 1);
  text-align: left;
}
.loginL2 {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  color: rgba(148, 159, 205, 1);
  text-align: left;
  vertical-align: top;
  font-size: 16px;
  white-space: nowrap;
}
.loginL3 {
  display: flex;
  width: 100%;
}
.loginL3 > div {
  opacity: 1;
  color: rgba(148, 159, 205, 1);
  flex: 1;
  font-size: 18px;
}
.loginL3 > div:nth-of-type(1) {
  border-right: 1px solid rgba(148, 159, 205, 1);
}
.loginL3 > div:nth-of-type(2) {
  text-align: center;
}
.loginL3 > div:nth-of-type(3) {
  border-left: 1px solid rgba(148, 159, 205, 1);
  text-align: right;
}
.fontBox {
  width: 490px;
  height: 171px;
  margin-top: 139px;
}
.fontBox > div:nth-of-type(1) {
  opacity: 1;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
.fontBox > div:nth-of-type(2) {
  opacity: 1;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
.fontBox > div:nth-of-type(3) {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.logo {
  width: 319px;
  height: 399px;
  text-align: center;
  margin-top: -195px;
}
.login input {
  width: 319px;
  height: 42px;
  opacity: 1;
  border-radius: 6px;
  border: 1.3px solid rgba(56, 103, 255, 1);
  outline: none;
}
.login button {
  width: 319px;
  height: 46px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(42, 85, 229, 1);
  border: 1px solid rgba(42, 85, 229, 1);
  box-shadow: 0px 1px 12px 0px rgba(148, 173, 255, 1);
  color: white;
}
.login  .el-form-item{
   margin-bottom:0
  }
::v-deep .el-form-item__content  .el-form-item__error{
    left: 40px;
  }
.inputBox{
  position: relative;
}
.inputBox>input{
  padding: 0 38px 0 38px;
  box-sizing: border-box;
  font-size: 12px;
  color: rgba(56, 103, 255, 1);
}
  ::v-deep .inputBox .el-input__suffix{
    right: 20px;
  }
  ::v-deep .inputBox .el-input__icon{
    line-height:42px;
    font-size: 16px;
  }
  ::v-deep .inputBox .el-input__inner{
    padding: 0 38px 0 38px;
    box-sizing: border-box;
    font-size: 12px;
    color: rgba(56, 103, 255, 1);
    width: 319px;
    height: 42px;
    opacity: 1;
    border-radius: 6px;
    border: 1px solid rgba(56, 103, 255, 1);
    outline: none;
  }
  .titleBox{
    width: 56px;
    height: 22px;
    position: absolute;
    line-height: 22px;
    text-align: center;
    font-size: 14px;
    color: rgba(56, 103, 255, 1);
    top: -11px;
    left: 30px;
    background: white;
  }
  .QR{
    margin-top: -195px;
  }
  .QRCode{
    width: 221px;
    height: 221px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .errTip{
    color: #f56c6c;
    position: absolute;
    font-size: 12px;
    right: 40px;
    top: 12px;
  }
</style>
